.roster {
    display: flex;
    flex-direction: row;
}

.header {
    display: flex;
    width: 100%;
    align-content: center;
}

thead {
    width: auto;
    border: 1;
    background-color: black;
    color: white;
}

th {
    font-size: medium;
    border: 1px solid black;
}

tr {
    line-height: .50rem;
    width: 500px;
    border: 1px solid navy;
}

td {
    width: 50%;
    font-size: medium;
    margin: 20px;
    align-self: auto;
}

.jersey {
    vertical-align: middle;
    background-color: navy;
    color: white;
    font-weight: bold;
    padding: 0 8px;
    font-style: italic;
}

.nameInfo {
    margin: 8px 0;
    font-size: large;
}

.contact {
    font-size: medium;
    display: block;
}

.position {
    width: '200px';
}

ul {
    list-style: none;
}

.player {
    display: flex;
    width: auto;
    align-content: space-between;
    height: 150px;
}

.name {
    font-size: large;
    font-style:italic;
    font-weight: bolder;
    padding-bottom: 20px;
}

.info {
    color: gray;
    font-style: italic;
    padding-bottom: 10px;
    border-bottom: gray;
    font-size: large;
}

.img {
    height: '100px';

}

.email {
    padding-top: 5px;
}

.phone {
    padding-top: 5px;
}
h1 {
    color: navy;
}

.social {
    padding-top: 10px;
}

.contactInfo {
    padding-top: 10px;
}

.playerInfo {
    padding-bottom: 10px;
}

.printCell {
    width:fit-content;
    font-size: smaller;
    padding: 3px;
}

.printRow {
    width: fit-content;
    line-height: 30px;
    border: 1px solid;
}

.printRowAlt {
    background-color: lightgray;
}

.printHeader {
    background-color: white;
    color: black;
    border: none;
}

.printHead {
    border-bottom: 1px solid black;
}